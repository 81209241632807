import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { DISPLAY_PHONE_NUMBER } from "../../config"
import { Icon } from "./Icon"

/* eslint-disable react/require-default-props */
PhoneNumberWithIcon.propTypes = {
	color: PropTypes.string,
	hover: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
}

export function PhoneNumberWithIcon({ color, hover, width, height }) {
	return (
		<Phone color={color} hover={hover}>
			<Icon name="phone" fill={color} hover={hover} width={width} height={height} />
			<span>{DISPLAY_PHONE_NUMBER}</span>
		</Phone>
	)
}

const Phone = styled.span`
	svg {
		position: relative;
		top: 3px;
	}

	path {
		transition: fill 0.15s ease;
	}
	&:hover path {
		${({ hover }) => `fill: ${hover} !important`};
	}

	span {
		position: relative;
		top: -2px;
		padding-bottom: 1px;
		margin-left: 5px;
		margin-right: 15px;
		font-weight: 700;
		font-size: 0.935rem;

		@media only screen and (min-width: 500px) {
			font-size: 1.015rem;
		}
		@media only screen and (min-width: 800px) {
			font-size: 1.125rem;
		}
	}
`
