import META from "./meta.js"
import { DIAL_PHONE_NUMBER, EMAIL, CITY, STATE_POSTAL_ABBR } from "./info.js"

//* Checklist
//* 1. Make sure logo url exists

/* eslint-disable camelcase */
export const SCHEMA_ldJSON = {
	"@context": "https://schema.org",
	"@type": "Organization",
	url: META.site_url,
	name: META.title,
	email: EMAIL,
	telephone: DIAL_PHONE_NUMBER,
	description: META.description,
	logo: {
		"@type": "ImageObject",
		url: `${META.site_url}/icons/icon-96x96.png`,
		width: 96,
		height: 96,
	},
	image: {
		"@type": "ImageObject",
		url: META.og_image,
		width: 1200,
		height: 630,
	},
	address: {
		"@type": "PostalAddress",
		addressCountry: "US",
		addressLocality: CITY,
		addressRegion: STATE_POSTAL_ABBR,
	},
	contactPoint: {
		"@type": "ContactPoint",
		telephone: DIAL_PHONE_NUMBER,
		email: EMAIL,
		contactType: "customer service",
	},
}
