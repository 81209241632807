module.exports = {
	favicon_path: "./src/assets/logos/favicon.png",

	// Do not use the trailing slash. / <- will break config
	site_url: "https://jamesgleason.tech",

	// If the title is changed, you must update in home page helmet title
	title: "James Gleason // Web Technologies",
	description: "Find out more about this highly innovative Web Technology Company.",
	keywords: "website design, web design, website development, web development",

	// TODO: Below here
	// Google Analytics
	google_verification: "v-2Vw-PBjxBb59ZTeY99YTxlaQn2LVR6mmYjWqSoSBw",

	og_image: "http://res.cloudinary.com/jameygleason/image/upload/v1536515924/JG-OG_image.png",

	og_prefix_website: "og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# website: http://ogp.me/ns/website#",

	og_prefix_article: "og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# article: http://ogp.me/ns/article#",
}
