import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { EMAIL } from "../../config/info.js"
import { Icon } from "./Icon.js"

/* eslint-disable react/require-default-props */
EmailWithIcon.propTypes = {
	color: PropTypes.string,
	hover: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
}

export function EmailWithIcon({ color, hover, width, height }) {
	return (
		<Email color={color} hover={hover}>
			<Icon name="email" width={width} height={height} fill={color} hover={hover} />
			<span>{EMAIL}</span>
		</Email>
	)
}

const Email = styled.span`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
	font-weight: 700;
	font-size: 0.835rem;

	@media only screen and (min-width: 500px) {
		font-size: 1rem;
	}

	svg {
		margin-right: 4px;
	}

	span {
		position: relative;
		top: 2px;
		transition: all 250ms ease;
	}
`
