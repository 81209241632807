import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTransition, animated } from "react-spring"
import { theme, elevation, Container } from "../styles"
import { Icon } from "./Icon"
import { Portal } from "./Portal"

Alert.propTypes = {
	toggle: PropTypes.func.isRequired,
}

export function Alert(props) {
	const { children, toggle, on } = props

	const transition = useTransition(on, null, {
		tension: 200,
		friction: 25,
		from: {
			opacity: 0.5,
			barY: "-150px",
			y: "-50px",
		},
		enter: {
			opacity: 1,
			barY: "0px",
			y: "0px",
		},
		leave: {
			opacity: 1,
			barY: "-150px",
			y: "-50px",
		},
	})

	return (
		<Portal>
			{transition.map(
				({ item, key, props: animation }) =>
					item && (
						<AlertContainer key={key}>
							<AlertBar
								style={{
									opacity: animation.opacity.interpolate(op => `${op}`),
									transform: animation.barY.interpolate(barY => `translate3d(0, ${barY}, 0)`),
								}}
							>
								<AlertClose
									style={{
										transform: animation.y.interpolate(y => `translate3d(0, ${y}, 0)`),
									}}
									onClick={toggle}
								>
									<Icon name="close" fill={theme.red} />
								</AlertClose>
								<ContentBlock
									style={{
										opacity: animation.opacity.interpolate(op => `${op}`),
										transform: animation.y.interpolate(y => `translate3d(0, ${y}, 0)`),
									}}
								>
									{children}
								</ContentBlock>
							</AlertBar>
						</AlertContainer>
					),
			)}
		</Portal>
	)
}

const AlertContainer = styled(Container)`
	position: fixed;
	top: 20px;
	left: 0;
	right: 0;
	z-index: 100;
`

const AlertBar = styled(animated.div)`
	width: 100%;
	min-height: 80px;
	background: ${theme.ghost};
	border-radius: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	${elevation[4]}
`

const ContentBlock = styled(animated.div)`
	text-align: center;
	color: ${theme.red};
	margin: 1.2em;

	p {
		margin-bottom: 0.35rem;
	}
`

const AlertClose = styled(animated.button)`
	position: absolute;
	top: 0;
	right: 0;
	padding: 20px 30px;
	background: none;
	border: none;
	z-index: 101;
`
