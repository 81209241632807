import styled, { keyframes } from "styled-components"
import { applyStyleModifiers } from "styled-components-modifiers"
import { rgba, white, black, darkAlt, fadedSlate, slate, darkSlate } from "./colors.js"

const loading = keyframes`
  0% {
    transform: translate3d(-50%, -20%, 0) scaleX(0) scaleY(0.75);
    opacity: 1;
  }
	3% {
    transform: translate3d(0%, 0 ,0) scaleX(1) scaleY(1);
    opacity: 1;
  }
	12% {
    transform: translate3d(0%, 0 ,0) scaleX(1) scaleY(1);
    opacity: 0;
  }
	100% {
    transform: translate3d(-50%, 0 ,0) scaleX(0) scaleY(0);
    opacity: 0;
  }
`

const BUTTON_MODIFIER = {
	light: () => `
    color: ${darkSlate};
    background-image: linear-gradient(${white}, 75%, ${fadedSlate});

    &:link,
    &:visited {
      color: ${darkSlate};
    }

    &:hover,
    &:focus {
      transform: translateY(-2px);
    }

    &:focus {
      outline: none;
    }

    &:active {
      background: ${white};
      color: ${darkSlate};
      box-shadow: none;
      transform: translateY(0px);
      border: solid 2px ${darkSlate};
    }
  `,
	center: () => `
    display: block;
    margin: 0 auto;
  `,
	width_auto: () => `
    width: max-content;
    padding: 0 1rem;
  `,
}

export const Button = styled.button`
	position: relative;
	display: inline-block;
	background-image: linear-gradient(${slate} 40%, ${darkSlate});
	cursor: pointer;
	height: 2.625rem;
	width: 12.5rem;
	line-height: 2.625rem;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	font-weight: 500;
	color: ${white};
	border-radius: 99px;
	text-align: center;
	font-size: 0.875rem;
	font-weight: 700;
	border: none;
	outline: none;
	box-shadow: 0 7px 13px -3px ${rgba(black, 0.3)}, 0 2px 4px 0 ${rgba(darkAlt, 0.4)}, inset 0 -2px 0 0 ${darkAlt};
	transition: transform 0.15s ease;
	will-change: transform;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: transparent;
		border-radius: 99px;
		transition: all 250ms ease;
	}

	&:hover::before,
	&:focus::before {
		background-image: linear-gradient(22deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 50%);
		animation-name: ${loading};
		animation-duration: 6s;
		animation-timing-function: ease-out;
		animation-delay: 0;
		animation-direction: linear;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
		border-radius: 99px;
		box-shadow: 0 11px 16px -3px ${rgba(black, 0.3)}, 0 4px 5px 0 ${rgba(black, 0.4)};
		transition: opacity 0.2s ease;
		z-index: -1;
	}

	&:hover:after {
		opacity: 0.5;
	}

	&:link,
	&:visited {
		color: ${white};
	}
	&:hover,
	&:focus {
		transform: translateY(-2px);
	}
	&:focus {
		outline: none;
	}
	&:active {
		background-image: linear-gradient(${slate} 40%, ${slate});
		box-shadow: none;
		transform: translateY(0px);
	}
	${applyStyleModifiers(BUTTON_MODIFIER)};
`

//* +---------------------+
//* Empty blank BUTTON
//* +---------------------+
export const BlankBtn = styled.button`
	all: unset;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
	color: ${({ color }) => (color ? `${color}` : `${darkAlt}`)};
	${applyStyleModifiers(BUTTON_MODIFIER)};
`
