// Color Definitions
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const theme = {
	black: "#191716",
	white: "#FCFDFF",
	ghost: "#f6f9fc",
	lightestGrey: "#f5f5fa",
	lighterGrey: "#eaeaef",
	lightGrey: "#eaeaef",
	grey: "#dcdce1",
	darkGrey: "#c4c4c8",
	darkerGrey: "#ababaf",
	darkestGrey: "#939396",
	darkAlt: "#32325d",
	slate: "#6b7caf",
	fadedSlate: "#7280A8",
	darkSlate: "#48557e",
	darkerSlate: "#525f7f",
	lighterGreen: "#74e4A2",
	lightGreen: "#24b47e",
	darkGreen: "#159570",
	darkerGreen: "#005d43",
	gold: "#ce7c3a",
	darkGold: "#6d3b12",
	lightRed: "#F93845",
	red: "#930000",
	hoverColor: "#7280A8",
}

export const black = "#191716"
export const white = "#FCFDFF"
export const ghost = "#f6f9fc"
export const lightestGrey = "#f5f5fa"
export const lighterGrey = "#eaeaef"
export const grey = "#dcdce1"
export const darkGrey = "#c4c4c8"
export const darkerGrey = "#ababaf"
export const darkestGrey = "#939396"

export const darkAlt = "#32325d"
export const slate = "#6b7caf"
export const fadedSlate = "#7280A8"
export const darkSlate = "#48557e"
export const darkerSlate = "#525f7f"

export const lighterGreen = "#74e4A2"
// Hover
export const lightGreen = "#24b47e"
export const darkGreen = "#159570"
export const darkerGreen = "#005d43"

export const gold = "#ce7c3a"
export const darkGold = "#6d3b12"
export const red = "#ff5b5c"

// Global Helpers
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const hoverColor = fadedSlate

// Gradients
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const vertWhiteGreyWhite = `linear-gradient(90deg, ${white}, 20%, ${lightestGrey}, 80%, ${white})`
export const horizontalWhiteGrey = `linear-gradient(${white}, ${lightestGrey})`
export const horWhiteDarkGrey = `linear-gradient(${white}, ${grey})`

// Color Functions
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export function rgba(hax, alpha) {
	let hex = hax
	hex = hex.replace("#", "")
	const r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16)
	const g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16)
	const b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16)
	if (alpha) {
		return `rgba(${r}, ${g}, ${b}, ${alpha})`
	}
	return `rgb(${r}, ${g}, ${b})`
}

/* eslint-disable no-bitwise, no-param-reassign */
export function tint(col, amt) {
	let usePound = false
	if (col[0] === "#") {
		col = col.slice(1)
		usePound = true
	}
	const num = parseInt(col, 16)
	let r = (num >> 16) + amt
	if (r > 255) r = 255
	else if (r < 0) r = 0
	let b = ((num >> 8) & 0x00ff) + amt
	if (b > 255) b = 255
	else if (b < 0) b = 0
	let g = (num & 0x0000ff) + amt
	if (g > 255) g = 255
	else if (g < 0) g = 0
	return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16)
}
