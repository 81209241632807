import React from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { theme, rgba, lightestGrey, slate, Container, Para } from "../styles"
import { ContactBtn } from "./ContactBtn"

export function Hero({ children, image, line1, line2, line3, para, noBtn, height }) {
	const animation = useSpring({
		tension: 280,
		friction: 80,
		delay: 300,
		from: {
			opacity: 0,
			y: 20,
		},
		opacity: 1,
		y: 0,
	})

	if (children)
		return (
			<Header height={height} image={image}>
				{children}
			</Header>
		)

	return (
		<Header height={height} image={image}>
			<AnimatedContainer
				style={{
					transform: animation.y.interpolate(y => `translate3d(0, ${y}px, 0)`),
					opacity: animation.opacity.interpolate(opacity => opacity),
				}}
			>
				<AnimatedPageHeading
					className="page-heading page-heading-uppercase"
					style={{
						transform: animation.y.interpolate(y => `translate3d(0, ${y}px, 0)`),
						opacity: animation.opacity.interpolate(opacity => opacity),
					}}
				>
					{line1}
					{line2 && <br />}
					{line2 && line2}
					{line3 && <br />}
					{line3 && line3}
				</AnimatedPageHeading>
				{para && (
					<AnimatedPara
						modifiers={["darkSlate", "center", "narrow"]}
						style={{
							transform: animation.y.interpolate(y => `translate3d(0, ${y}px, 0)`),
							opacity: animation.opacity.interpolate(opacity => opacity),
						}}
					>
						{para}
					</AnimatedPara>
				)}
				{noBtn ? null : (
					<AnimatedBtnBox>
						<ContactBtn
							modifiers={["center"]}
							style={{
								transform: animation.y.interpolate(y => `translate3d(0, ${y}px, 0)`),
								opacity: animation.opacity.interpolate(opacity => opacity),
							}}
						>
							Get in touch
						</ContactBtn>
					</AnimatedBtnBox>
				)}
			</AnimatedContainer>
		</Header>
	)
}

export const Header = styled.header`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${({ height }) => (height > 0 ? `${height}px` : "600px")};
	background-image: linear-gradient(
			-215deg,
			rgb(255 255 255 / 0),
			14%,
			${rgba(lightestGrey, 0.25)},
			25%,
			${rgba(lightestGrey, 0.8)},
			50%,
			${rgba(slate, 0.25)}
		),
		radial-gradient(${lightestGrey} 20%, ${rgba(lightestGrey, 0.5)} 56%, rgb(255 255 255 / 0) 70%);

	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		height: ${({ height }) => (height > 0 ? `${height}px` : "600px")};
		background-image: ${({ image }) =>
			image
				? `url(${image})`
				: "url('https://res.cloudinary.com/jameygleason/image/upload/f_auto,q_auto/v1542483034/bg.jpg')"};
		background-position: center;
		background-size: cover;
		z-index: -1;
	}

	p {
		color: ${theme.darkAlt};
		font-size: 1.4rem;
		line-height: 1.25;
		margin-top: 1.5rem;
		letter-spacing: -0.1px;

		@media only screen and (min-width: 500px) {
			font-size: 1.375rem;
		}

		@media only screen and (min-width: 800px) {
			font-size: 1.5rem;
		}
	}

	a {
		margin-top: 2.25rem;
	}
`

const AnimatedContainer = styled(animated(Container))``
const AnimatedPageHeading = styled(animated("h1"))``
const AnimatedPara = styled(animated(Para))``
const AnimatedBtnBox = styled(animated.div)`
	margin: 0 auto;
`
