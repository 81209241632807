import styled from "styled-components"
import { applyStyleModifiers } from "styled-components-modifiers"

import { fadedSlate, slate, darkAlt, darkSlate } from "./colors"

const PARA_MODIFIER = {
	left: () => `
    text-align: left;
    margin-left: 0;
  `,
	uppercase: () => `
    text-transform: uppercase;
  `,
	center: () => `
    text-align: center;
  `,
	fadedSlate: () => `
    color: ${fadedSlate};
  `,
	slate: () => `
    color: ${slate};
  `,
	darkSlate: () => `
    color: ${darkSlate};
  `,
	narrow: () => `
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
  `,
	xtraNarrow: () => `
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
  `,
	smallMargins: () => `
    margin: 0.2em auto;
  `,
}

export const Para = styled.p`
	color: ${darkAlt};
	${applyStyleModifiers(PARA_MODIFIER)};
`
