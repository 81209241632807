import React from "react"
import { Link } from "gatsby"
import { Button } from "../styles"

export function ContactBtn(props) {
	const { children, modifiers, className } = props

	return (
		<Button as={Link} to="/contact" modifiers={modifiers && [...modifiers]} className={className}>
			{children}
		</Button>
	)
}
