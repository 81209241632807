import React from "react"
import styled from "styled-components"
// import { GITHUB_URL, TWITTER_URL } from "../../../config/links.js"
import { GITHUB_URL } from "../../../config/links.js"
import { Icon } from "../../lib/Icon.js"
import { theme } from "../../styles/colors.js"
import { Anchor } from "../../styles/anchors.js"
import { Container } from "../../styles/containers.js"
import { TinyStrongHeading } from "../../styles/headings.js"
import { mainMenuLinks } from "../navigation/mainMenuLinks.js"

export default function SectionTwo() {
	return (
		<FooterBlock modifiers={["section", "no_top_pad", "no_bottom_pad"]}>
			<TinyStrongHeading modifiers={["theme.darkAlt"]}>Pages</TinyStrongHeading>
			<FooterNav>
				<ul>
					{mainMenuLinks.map(({ key, path, name }) => (
						<li key={key}>
							{key <= 3 && (
								<>
									{key === 1 ? <span className="footer--nav_bullet">&bull;</span> : <span>&bull;</span>}
									<Anchor to={path} color={theme.darkAlt} hover={theme.hoverColor}>
										{name}
									</Anchor>
								</>
							)}
						</li>
					))}
				</ul>
				<ul>
					<li>
						{/* <a href="https://signalchain.dev" target="self">
							Blog & Tutorials
						</a> */}

						{/* <Anchor to="/blog" color={theme.darkAlt} hover={theme.hoverColor}>
							Blog & Tutorials
						</Anchor> */}
					</li>
				</ul>
			</FooterNav>
			<SocialIcons>
				<a aria-label="link to github" href={GITHUB_URL} target="self">
					<Icon name="github" fill={theme.darkAlt} width={30} height={30} />
				</a>
				{/* <a aria-label="link to youtube channel" href={YOUTUBE_URL} target="self">
					<Icon name="youtube" fill={theme.darkAlt} width={30} height={24} />
				</a> */}
				{/* <a aria-label="link to twitter account" href={TWITTER_URL} target="self">
					<Icon name="twitter" fill={theme.darkAlt} width={28} height={24} />
				</a> */}
			</SocialIcons>
		</FooterBlock>
	)
}

const FooterBlock = styled(Container)`
	display: grid;
	grid-template-rows: 2rem 1fr 1fr;
	justify-items: center;
	text-align: center;
	flex-basis: 33%;
`

const FooterNav = styled.div`
	font-size: 1.25rem;
	font-weight: 600;
	transition: all 250ms ease;

	.footer--nav_bullet:first-child {
		display: none;
	}

	li {
		display: inline-block;
		margin: 0;
	}

	span {
		margin: 0 4px;
	}
`

const SocialIcons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 6px;

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px;
	}

	svg:hover path {
		transition: all 250ms ease;
		fill: ${theme.hoverColor};
	}
`
