import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { theme } from '../../../styles'

Logo.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  hover: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

Logo.defaultProps = {
  className: '',
  fill: `${theme.darkAlt}`,
  hover: `${theme.hoverColor}`,
  width: '60px',
  height: '60px',
}

function Logo(props) {
  const { fill, hover, className, width, height } = props

  return (
    <LogoSVG
      className={className}
      style={{ width: `${width}px`, height: `${height}px` }}
      viewBox="0 0 945 1000"
      hover={hover}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M472.5 60.0532L60 281.829V718.171L472.5 939.947L885 718.171V281.829L472.5 60.0532ZM500.505 6.98777C483.175 -2.32925 461.825 -2.32926 444.495 6.98776L28.0049 230.909C10.6754 240.226 0 257.445 0 276.079V723.921C0 742.555 10.6754 759.774 28.0049 769.091L444.495 993.012C461.825 1002.33 483.175 1002.33 500.505 993.012L916.995 769.091C934.325 759.774 945 742.555 945 723.921V276.079C945 257.445 934.325 240.226 916.995 230.909L500.505 6.98777ZM433.522 491.212C433.522 451.275 442.174 415.755 459.479 384.65C477.169 353.161 501.588 328.777 532.738 311.497C563.887 293.832 599.458 285 639.452 285C687.907 285 728.67 296.712 761.742 320.137C795.198 343.561 817.31 376.01 828.078 417.483H719.056C711.749 404.042 701.174 393.866 687.33 386.954C673.87 379.658 657.911 376.01 639.452 376.01C607.534 376.01 581.961 386.57 562.733 407.69C543.505 428.427 533.891 456.267 533.891 491.212C533.891 528.461 543.89 557.645 563.887 578.766C584.268 599.502 612.149 609.87 647.528 609.87C671.755 609.87 692.329 603.726 709.25 591.438C726.17 578.766 738.284 560.909 745.59 537.869H620.417V465.867H835V557.453C827.693 582.03 815.195 604.878 797.506 625.999C780.201 647.119 758.088 664.207 731.169 677.264C704.25 690.32 673.87 696.848 640.029 696.848C600.035 696.848 564.271 688.208 532.738 670.927C501.588 653.263 477.169 628.879 459.479 597.774C442.174 566.669 433.522 531.149 433.522 491.212ZM403 566.35V296H294.149V566.35C294.149 580.678 290.542 591.613 283.328 599.154C276.113 606.695 265.929 610.465 252.773 610.465C238.345 610.465 227.311 606.318 219.672 598.023C212.034 589.35 208.214 576.719 208.214 560.128H100C100 603.867 114.216 637.614 142.649 661.368C171.082 685.123 208.851 697 255.956 697C300.939 697 336.586 685.688 362.897 663.065C389.632 640.441 403 608.203 403 566.35Z"
        fill={fill}
      />
    </LogoSVG>
  )
}

const LogoSVG = styled.svg`
  &:hover {
    path {
      fill: ${({ hover }) => hover};
    }
  }
`

export default Logo
