import styled from "styled-components"
import { applyStyleModifiers } from "styled-components-modifiers"
import { darkAlt } from "./colors.js"
import { brandFont } from "./typography.js"

const HEADING_MODIFIER = {
	brandFont: () => `
    ${brandFont};
  `,
	left: () => `
    text-align: left;
  `,
	noTrans: () => `
    text-transform: none;
  `,
	uppercase: () => `
    text-transform: uppercase;
  `,
	center: () => `
    text-align: center;
  `,
	smallMargins: () => `
  margin: 0.2em auto;
  `,
	letterSpace: () => `
    letter-spacing: 1.5px;
  `,
	strong: () => `
    font-weight: 700;
  `,
}

export const SectionHeading = styled.h2`
	${brandFont};
	color: ${({ color }) => (color ? `${color}` : `${darkAlt}`)};
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1;
	margin-bottom: 1.32rem;

	@media only screen and (min-width: 800px) {
		font-size: 2rem;
	}
	${applyStyleModifiers(HEADING_MODIFIER)};
`

export const SubHeading = styled.h3`
	${brandFont};
	color: ${({ color }) => color || darkAlt};
	font-size: 1.215rem;
	font-weight: 400;
	line-height: 1;
	margin-bottom: 0.4em;
	${applyStyleModifiers(HEADING_MODIFIER)};
`

export const Level4Heading = styled.h4`
	${brandFont};
	color: ${({ color }) => (color ? `${color}` : `${darkAlt}`)};
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1;
	margin-bottom: 0.4em;
	${applyStyleModifiers(HEADING_MODIFIER)};
`

export const SmlStrongHeading = styled.h3`
	${brandFont};
	color: ${({ color }) => (color ? `${color}` : `${darkAlt}`)};
	font-size: 1.25rem;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.75px;
	line-height: 1;
	margin-bottom: 0.4em;
	${applyStyleModifiers(HEADING_MODIFIER)};
`

export const TinyStrongHeading = styled.h3`
	${brandFont};
	color: ${({ color }) => (color ? `${color}` : `${darkAlt}`)};
	font-size: 0.875rem;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	line-height: 1;
	margin-bottom: 1rem;
	${applyStyleModifiers(HEADING_MODIFIER)};
`
