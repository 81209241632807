import { useState } from "react"

export function Toggle({ children }) {
	const [on, setOn] = useState(false)

	function toggle() {
		setOn(!on)
	}

	return children({
		on,
		toggle: () => toggle(),
	})
}
