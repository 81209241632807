export const brandFont = `
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 700;
`

export const headingFont = `
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 700;
`

export const baseFont = `
  font-family: 'Hind', Arial, sans-serif;
  font-weight: 400;
`

export const serif = `
  font-weight: 400;
  font-style: italic;
  font-family: ${baseFont};
`
