import React from "react"
import styled from "styled-components"
import { rgba, darkAlt, slate, brandFont } from "../../styles"
import SectionOne from "./SectionOne"
import SectionTwo from "./SectionTwo"
import SectionThree from "./SectionThree"

export default function Footer() {
	return (
		<MainFooter>
			<SectionOne />
			<SectionTwo />
			<SectionThree />
		</MainFooter>
	)
}

const MainFooter = styled.footer`
	position: relative;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	color: ${darkAlt};
	border-top: solid 1px ${rgba(slate, 0.3)};
	padding: 4rem 0 0;
	overflow: hidden;

	@media only screen and (min-width: 1010px) {
		flex-direction: row;
	}

	section {
		@media only screen and (max-width: 960px) {
			margin-bottom: 2.75rem;
		}
	}

	h2 {
		${brandFont};
		letter-spacing: 1.5px;
		text-transform: uppercase;
	}

	& h2,
	p,
	div,
	span {
		letter-spacing: 0.2px;
	}
`
