import React from "react"
import styled from "styled-components"
import { Container, horizontalWhiteGreyAfterBG, SectionHeading, Para } from "../styles"

export function SectionLead(props) {
	const { children, heading, para, modifiers, background, className } = props

	return (
		<OuterContainer modifiers={["section", "full_width", ...modifiers]} background={background} className={className}>
			<SectionHeading modifiers={["center"]}>{heading}</SectionHeading>
			<Para modifiers={["center", "narrow"]}>{para}</Para>
			{children}
		</OuterContainer>
	)
}

const OuterContainer = styled(Container)`
	${({ background }) => (background ? `background: ${background};` : horizontalWhiteGreyAfterBG)};
`
