import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon } from "../../lib"
import { theme } from "../../styles"

MenuCloseButton.propTypes = {
	toggle: PropTypes.func.isRequired,
}

export default function MenuCloseButton({ toggle }) {
	return (
		<MenuClose onClick={toggle}>
			<Icon name="close" fill={theme.white} />
		</MenuClose>
	)
}

const MenuClose = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	padding: 1.375rem 1rem;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;

	&:hover path {
		fill: ${theme.white};
		transition: all 250ms ease;
	}
`
