import styled from "styled-components"
import { applyStyleModifiers } from "styled-components-modifiers"
import PropTypes from "prop-types"

import { white } from "./colors"

const CONTAINER_MODIFIER = {
	full_width: () => `
    width: 100%; max-width: 100%;
    @media only screen and (min-width: 800px) {  width: 100%; max-width: 100%; }
    @media only screen and (min-width: 1200px) { width: 100%; max-width: 100%; }
  `,
	narrow: () => `
    max-width: 930px;
    @media only screen and (min-width: 800px) { max-width: 930px }
    @media only screen and (min-width: 1200px) { max-width: 930px }
  `,
	small: () => `
    max-width: 730px;
    @media only screen and (min-width: 800px) { max-width: 730px }
    @media only screen and (min-width: 1200px) { max-width: 730px }
  `,
	section: () => `
    @media only screen and (max-width: 500px) { padding-top: 30px; padding-bottom: 30px; }
    padding-top: 60px; padding-bottom: 60px;
    @media only screen and (min-width: 800px) { padding-top: 80px; padding-bottom: 80px; }
    @media only screen and (min-width: 1200px) { padding-top: 120px; padding-bottom: 120px; }
  `,
	block: () => `
    @media only screen and (max-width: 500px) { padding-top: 20px; padding-bottom: 20px; }
    padding-top: 20px; padding-bottom: 20px;
    @media only screen and (min-width: 800px) { padding-top: 20px; padding-bottom: 20px; }
    @media only screen and (min-width: 1200px) { padding-top: 20px; padding-bottom: 20px; }
  `,
	nested: () => `
    @media only screen and (max-width: 500px) { padding-left: 0; padding-right: 0; }
    padding-left: 0; padding-right: 0;
    @media only screen and (min-width: 800px) { padding-left: 0; padding-right: 0; }
    @media only screen and (min-width: 1200px) { padding-left: 0; padding-right: 0; }
  `,
	no_top_pad: () => `
    @media only screen and (max-width: 500px) { padding-top: 0px; }
    padding-top: 0px;
    @media only screen and (min-width: 800px) { padding-top: 0px; }
    @media only screen and (min-width: 1200px) { padding-top: 0px; }
  `,
	no_bottom_pad: () => `
    @media only screen and (max-width: 500px) { padding-bottom: 0px;}
    padding-bottom: 0px;
    @media only screen and (min-width: 800px) { padding-bottom: 0px; }
    @media only screen and (min-width: 1200px) { padding-bottom: 0px; }
  `,
	no_right_margin: () => `
    @media only screen and (max-width: 500px) { margin-right: 0px; }
    margin-right: 0px;
    @media only screen and (min-width: 800px) { margin-right: 0px; }
    @media only screen and (min-width: 1200px) { margin-right: 0px; }
  `,
	no_left_margin: () => `
    @media only screen and (max-width: 500px) { margin-left: 0px; }
    margin-left: 0px;
    @media only screen and (min-width: 800px) { margin-left: 0px; }
    @media only screen and (min-width: 1200px) { margin-left: 0px; }
  `,
}

export const Container = styled.section`
	/* 
  //* +---------------------+
  //* below 500px
  //* +---------------------+
  */
	@media only screen and (max-width: 500px) {
		max-width: 500px;
		padding-left: 18px;
		padding-right: 18px;
	}

	/* 
  //* +---------------------+
  //* BASE
  //* +---------------------+
  */
	margin: 0 auto;
	max-width: 694px;
	padding-left: 22px;
	padding-right: 22px;

	${({ background }) => background && `background: ${background}`};

	/* 
  //* +---------------------+
  //* above MED 
  //* +---------------------+
  */
	@media only screen and (min-width: 800px) {
		max-width: 996px;
		padding-left: 48px;
		padding-right: 48px;
	}

	/* 
  //* +---------------------+
  //* above xtra lrg 
  //* +---------------------+
  */
	@media only screen and (min-width: 1200px) {
		max-width: 1320px;
		padding-left: 60px;
		padding-right: 60px;
	}
	${applyStyleModifiers(CONTAINER_MODIFIER)};
`

Container.propTypes = {
	background: PropTypes.string,
}
Container.defaultProps = () => ({
	background: `${white}`,
})
