import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { useTransition, animated } from "react-spring"
import { theme, rgba, slate } from "../styles/colors.js"
import { Card } from "../styles/cards.js"
import { Icon } from "./Icon.js"
import { Portal } from "./Portal.js"

export function Modal({ children, toggle, on }) {
	const modalBG = useRef()
	const modalCard = useRef()

	const transition = useTransition(on, null, {
		tension: 200,
		friction: 25,
		from: {
			opacity: 0,
			closeBtnY: "-5px",
			y: "-35px",
		},
		enter: {
			opacity: 1,
			closeBtnY: "0px",
			y: "0px",
		},
		leave: {
			opacity: 0,
			closeBtnY: "-5px",
			y: "-35px",
		},
	})

	useEffect(() => {
		if (on && modalBG && modalBG.offsetHeight - 100 < modalCard.offsetHeight) {
			modalCard.setAttribute("style", "position: absolute; top: 0px; margin-top: 5vh; margin-bottom: 60px")
			document.body.style.overflow = "hidden"
			modalBG.style.overflowY = "scroll"
		}

		if (!on) {
			document.body.style.overflowY = "scroll"
		}

		// eslint-disable-next-line func-names
		return function () {
			return null
		}
	}, [])

	return (
		<Portal>
			{transition.map(
				({ item, key, props: animation }) =>
					item && (
						<ModalBG
							ref={modalBG}
							key={key}
							onClick={toggle}
							style={{
								opacity: animation.opacity.interpolate(opacity => opacity),
							}}
						>
							<ModalCard
								ref={modalCard}
								style={{
									transform: animation.y.interpolate(y => `translate3d(0, ${y}, 0)`),
									opacity: animation.opacity.interpolate(opacity => opacity),
								}}
							>
								<ModalClose
									onClick={toggle}
									style={{
										transform: animation.closeBtnY.interpolate(closeBtnY => `translate3d(0, ${closeBtnY}, 0)`),
									}}
								>
									<Icon name="close" fill={theme.black} />
								</ModalClose>
								<ModalCopyBlock>{children}</ModalCopyBlock>
							</ModalCard>
						</ModalBG>
					),
			)}
		</Portal>
	)
}

const ModalBG = styled(animated.div)`
	background: linear-gradient(150deg, #d1e0f0 15%, ${rgba(slate, 0.8)} 60%);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	z-index: 100;
`

const AnimCard = Card.withComponent(animated.div)

const ModalCard = styled(AnimCard)`
	position: relative;
	top: -3vh;
	text-align: center;
	margin-left: 8px;
	margin-right: 8px;
	min-width: 275px;
	max-width: 730px;
`

const ModalCopyBlock = styled.div`
	padding: 16px 14px 14px;
`

const ModalClose = styled(animated.button)`
	position: absolute;
	top: 0;
	right: 0;
	padding: 25px 30px;
	background: none;
	border: none;
	cursor: pointer;
`
