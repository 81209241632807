import { formatYear } from "../src/utils/formatDate.js"
import META from "./meta.js"

export const SITE_NAME = META.title
export const SITE_URL = META.site_url
export const DISPLAY_PHONE_NUMBER = "TODO"
export const DIAL_PHONE_NUMBER = "TODO"
export const EMAIL = "signalchain.dev@gmail.com"
export const CITY = "TODO"
export const STATE = "TODO"
export const STATE_POSTAL_ABBR = "TODO"
export const FOUNDED_DATE = 2018
export const IN_BUSINESS_FOR = formatYear(Date.now()) - FOUNDED_DATE
export const TAGLINE = "Distinctive interfaces personalizing your business"
