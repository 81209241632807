import React from "react"
import styled from "styled-components"
import { hours } from "../../../config"
import { Container, TinyStrongHeading } from "../../styles"

export default function SectionThree() {
	return (
		<FooterBlock modifiers={["section", "no_top_pad", "no_bottom_pad"]}>
			<TinyStrongHeading modifiers={["darkAlt"]}>Hours</TinyStrongHeading>
			<Hours>
				<ColumnUl>
					{hours.map(data => (
						<li key={data.key} className={`${data.key >= 5 ? "display_none" : "col_one_days"}`}>
							<span>{data.day}</span>
							<span>
								{data.open} &bull; {data.close}
							</span>
						</li>
					))}
				</ColumnUl>
				<ColumnUl>
					{hours.map(data => (
						<li key={data.key} className={`${data.key <= 4 ? "display_none" : "col_two_days"}`}>
							<span>{data.day}</span>
							{data.key <= 5 ? (
								<span>
									{data.open} &bull; {data.close}
								</span>
							) : (
								<span>{data.open}</span>
							)}
						</li>
					))}
				</ColumnUl>
			</Hours>
		</FooterBlock>
	)
}

const FooterBlock = styled(Container)`
	display: grid;
	grid-template-rows: 2rem 125px;
	justify-items: center;
	text-align: center;
	flex-basis: 33%;
`

const Hours = styled.div`
	display: grid;
	grid-template: 1fr / 1fr 1fr;
	text-align: left;
	grid-gap: 10px;
	width: 275px;
	margin-left: 15px;
	font-size: 1.185rem;
	font-weight: 600;

	.display_none {
		display: none;
	}
`

const ColumnUl = styled.ul`
	max-width: 100px;

	li {
		display: grid;
	}

	.col_one_days {
		grid-template: 1fr / 56px 75px;
	}
	.col_two_days {
		grid-template: 1fr / 40px 75px;
	}
`
