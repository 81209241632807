import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { black } from "./colors"
import { Para } from "./paragraphs"

export function Input(props) {
	const { name, label, placeholder, onChange, value, onBlur, type, rows, ariaLabel, required, marginBottom } = props

	return (
		<FormRow value={value} marginBottom={marginBottom}>
			{type === "textarea" ? (
				<>
					<textarea
						id={name}
						name={name}
						type={type}
						placeholder={placeholder}
						value={value}
						rows={rows}
						onChange={onChange}
						onBlur={onBlur}
						marginBottom={marginBottom}
					/>
					<label htmlFor={name} style={{ fontSize: "1px", opacity: 0 }}>
						{label || name}
					</label>
				</>
			) : (
				<>
					<input
						id={name}
						name={name}
						type={type}
						value={value}
						aria-label={ariaLabel}
						required={required}
						onChange={onChange}
						onBlur={onBlur}
						marginBottom={marginBottom}
					/>
					<label htmlFor={name}>{label || name}</label>
				</>
			)}
		</FormRow>
	)
}

const FormRow = styled.div`
	position: relative;
	padding-top: 20px;
	margin-bottom: 1rem;
	${({ marginBottom }) => `margin-bottom: ${marginBottom}px` || null};

	label {
		transform: translate3d(0, 0, 0);
		transform-origin: 0 0;
		margin-bottom: 5px;
		transition: all 250ms ease;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	input {
		width: 100%;
		margin: 0;
		font-weight: 400;
		border: none;
		border-radius: 0px;
		border-bottom: solid 2px ${black};
	}
	textarea {
		width: 100%;
		font-weight: 400;
		padding: 8px;
		border-radius: 0px;
	}
	input:focus + label {
		transform: translate3d(0, -160%, 0) scale(0.8);
		backface-visibility: hidden;
		margin-bottom: -10px;
	}
	${({ value }) => {
		if (typeof value === "string" && value.length > 0) {
			return css`
				label {
					transform: translate3d(0, -160%, 0) scale(0.8);
					backface-visibility: hidden;
				}
			`
		}
		if (typeof value !== "string" && toString(value).length > 0) {
			return css`
				label {
					transform: translate3d(0, -160%, 0) scale(0.8);
					backface-visibility: hidden;
				}
			`
		}

		return ""
	}};
`

/* eslint-disable react/require-default-props */
Input.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
	type: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	rows: PropTypes.string,
}

Input.defaultProps = {
	rows: 20,
	value: "",
	type: "text",
	onChange: () => null,
	onBlur: () => null,
}

export function Checkbox(props) {
	const { children, type, name, onChange, value, label, ariaLabel, onBlur, checked, marginBottom, marginTop } = props

	return (
		<CBBlock marginBottom={marginBottom} marginTop={marginTop}>
			<CheckboxInput
				onChange={onChange}
				onBlur={onBlur}
				id={name}
				name={name}
				type={type}
				value={value}
				aria-label={ariaLabel}
				checked={checked}
				marginBottom={marginBottom}
				marginTop={marginTop}
			/>
			<StyledPara>{children}</StyledPara>
			<label htmlFor={name}>{label || name}</label>
		</CBBlock>
	)
}

const CBBlock = styled.div`
	position: relative;
	display: flex;
	margin: 0 auto;
	${({ marginTop }) => marginTop && `margin-top: ${marginTop}px`};
	${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};

	label {
		font-size: 0px;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
	}
`

const CheckboxInput = styled.input`
	flex-basis: 40px;
	height: 20px;
	width: 20px;
	margin-top: 1px;
`

const StyledPara = styled(Para)`
	margin-left: 12px;
	em {
		font-size: inherit;
		line-height: inherit;
		padding: 0;
		margin: 0;
	}
`

Checkbox.propTypes = {
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: PropTypes.string,
	ariaLabel: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	marginBottom: PropTypes.number,
}

Checkbox.defaultProps = {
	value: "",
	type: "checkbox",
	onChange: () => null,
	onBlur: () => null,
}
