import React, { Component } from "react"
import Helmet from "react-helmet"
import { SITE_NAME, SITE_URL } from "../../config/info.js"
import META from "../../config/meta.js"
import { SCHEMA_ldJSON } from "../../config/schema.js"
import env from "../publicEnv.js"

export default class Head extends Component {
	// eslint-disable-next-line react/state-in-constructor
	state = {
		siteName: SITE_NAME || "",
		pageTitle: "",
	}

	componentDidMount() {
		const { location, data } = this.props
		const pageTitle = location.pathname
			.split("/")
			.map(parts => parts.split("-"))[1]
			.map(pieces => pieces.charAt(0).toUpperCase() + pieces.slice(1))
			.join(" ")
		this.setState({
			siteName: data.site.siteMetadata.title,
			pageTitle,
		})
	}

	render() {
		const { siteName, pageTitle } = this.state
		return (
			<Helmet
				title={pageTitle !== "" ? `${siteName} // ${pageTitle}` : siteName}
				meta={[
					// BASIC
					{
						name: "description",
						content: META.description,
					},
					{
						name: "keywords",
						content: META.keywords,
					},
					{
						name: "google-site-verification",
						content: META.google_verification,
					},
					// OG
					{ property: "og:type", content: "website" },
					{ property: "og:url", content: SITE_URL },
					{
						property: "og:title",
						content: SITE_NAME,
					},
					{
						property: "og:image",
						content: META.og_image,
					},
					{
						property: "og:description",
						content: META.description,
					},
					{
						property: "og:site_name",
						content: SITE_NAME,
					},
					{
						property: "og:locale",
						content: "en_us",
					},
				]}
			>
				<html lang="en" prefix={META.og_prefix_website} />
				<meta
					httpEquiv="Content-Security-Policy"
					content={`default-src 'self' 'unsafe-inline' 'unsafe-eval'; img-src 'self' data: http://www.w3.org/2000/svg http://www.w3.org/1999/xlink https://res.cloudinary.com/jameygleason/; font-src 'self' :data; connect-src 'self' ${env.CONTACT_WORKER_URL}`}
				/>
				<script type="application/ld+json" defer>
					{JSON.stringify(SCHEMA_ldJSON)}
				</script>
			</Helmet>
		)
	}
}
