import React from 'react'
import PropTypes from 'prop-types'
import { theme } from '../../styles'
import Logo from './components/Logo'

BrandLogo.propTypes = {
  name: PropTypes.string.isRequired,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  hover: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
}

BrandLogo.defaultProps = {
  stroke: 'black',
  fill: 'black',
  hover: `${theme.hoverColor}`,
  width: 48,
  height: 48,
  className: '',
}

function BrandLogo(props) {
  const { name, stroke, fill, hover, className, width, height } = props
  const err = 'Please supply valid prop to the icon element.'

  switch (name) {
    case 'logo':
      return (
        <Logo
          name={name}
          stroke={stroke}
          fill={fill}
          className={className}
          width={width}
          height={height}
          hover={hover}
        />
      )
    default:
      throw err
  }
}

export default BrandLogo
