import React from "react"
import styled from "styled-components"
import { EmailWithIcon } from "../../lib"
import { theme, Container, TinyStrongHeading } from "../../styles"

export default function SectionOne() {
	return (
		<FooterBlock modifiers={["section", "no_top_pad", "no_bottom_pad"]}>
			<TinyStrongHeading modifiers={["theme.darkAlt"]}>Contact</TinyStrongHeading>
			<div>
				<Name>James Gleason</Name>
				<p>
					<EmailWithIcon color={theme.darkAlt} hover={theme.hoverColor} />
				</p>
			</div>
		</FooterBlock>
	)
}

const FooterBlock = styled(Container)`
	display: grid;
	grid-template-rows: 2rem 1fr;
	justify-items: center;
	text-align: center;
	flex-basis: 33%;

	p {
		margin: -2px;
	}

	p:nth-of-type(1) {
		margin-top: 0px;
	}
`

const Name = styled.div`
	color: ${theme.darkAlt};
	font-size: 1.285rem;
	font-weight: 600;
`
