export const hours = [
	{
		key: 1,
		day: "Mon",
		open: "9a",
		close: "5p",
	},
	{
		key: 2,
		day: "Tues",
		open: "9a",
		close: "5p",
	},
	{
		key: 3,
		day: "Weds",
		open: "9a",
		close: "5p",
	},
	{
		key: 4,
		day: "Thurs",
		open: "9a",
		close: "5p",
	},
	{
		key: 5,
		day: "Fri",
		open: "9a",
		close: "5p",
	},
	{
		key: 6,
		day: "Sat",
		open: "By appt.",
		close: null,
	},
	{
		key: 7,
		day: "Sun",
		open: "By appt.",
		close: null,
	},
]
