import { horizontalWhiteGrey } from "./colors.js"

export const horizontalWhiteGreyAfterBG = `
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 100%;
    min-height: 250px;
    max-height: 350px;
    background: ${horizontalWhiteGrey};
    z-index: -1;
  }
`
