// Main Navigation
export const mainMenuLinks = [
	{
		key: 1,
		name: "Home",
		path: "/",
	},
	{
		key: 2,
		name: "About",
		path: "/about",
	},
	{
		key: 3,
		name: "Contact",
		path: "/contact",
	},
	// {
	//   key: 4,
	//   name: 'Open Source',
	//   path: '/open-source',
	// },
	// {
	//   key: 4,
	//   name: 'Blog & Tutorials',
	//   path: '/blog',
	// },
]
