import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { SITE_NAME } from '../../../config'
import BrandLogo from './BrandLogo'

/* eslint-disable react/require-default-props */
LogoLink.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  hover: PropTypes.string,
}

export default function LogoLink(props) {
  const { className, name, fill, width, height, hover } = props

  return (
    <LogoLinkWrapper to="/" aria-label={`${SITE_NAME} homepage`} className={className}>
      <BrandLogo name={name} width={width} height={height} fill={fill} hover={hover} />
    </LogoLinkWrapper>
  )
}

const LogoLinkWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &:focus {
    outline: none;
  }

  svg {
    transition: all 250ms ease;
  }
`
