import React from "react"
import styled from "styled-components"
import { mainMenuLinks } from "./mainMenuLinks.js"
import { darkAlt, white } from "../../styles/colors.js"
import { Anchor } from "../../styles/anchors.js"

export default function MainMenu() {
	return (
		<NavList>
			{mainMenuLinks.map(
				({ key, name, path }) =>
					name !== "Home" && (
						<li key={key}>
							<Anchor to={path} className="main-nav-link">
								{name}
							</Anchor>
						</li>
					),
			)}
			<li>
				{/* <a href="https://signalchain.dev" className="main-nav-link" target="self">
					Blog & Tutorials
				</a> */}
				{/* <Anchor to="/blog" className="main-nav-link">
					Blog & Tutorials
				</Anchor> */}
			</li>
		</NavList>
	)
}

const NavList = styled.ul`
	display: none;
	justify-content: center;
	align-items: center;
	margin-left: 25px;
	z-index: 1;

	@media only screen and (min-width: 800px) {
		display: flex;
	}

	li {
		list-style: none;
		margin: 0 2px;
	}

	.main-nav-link {
		color: ${darkAlt};
		font-size: 1rem;
		font-weight: 600;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		padding: 5px 10px 4px;
		outline: none;
		border: none;
		cursor: pointer;

		&:hover,
		&:focus {
			color: ${white};
			background: ${darkAlt};
			border-radius: 5px;
		}
	}
`
