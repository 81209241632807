import React from "react"
import styled from "styled-components"
import { IconHeading } from "../../lib"
import { theme, BlankBtn } from "../../styles"

export default function MenuOpenBtn({ toggle }) {
	return (
		<MenuIcon onClick={() => toggle()}>
			<IconHeading
				color={theme.darkAlt}
				hover={theme.fadedSlate}
				name="menuIcon"
				width={32}
				height={15}
				marginTop={-6}
				reverse
			>
				Menu
			</IconHeading>
		</MenuIcon>
	)
}

const MenuIcon = styled(BlankBtn)`
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
	padding-right: 8px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;

	div {
		margin-bottom: 0;
	}

	sup {
		color: ${theme.darkAlt};
	}

	@media only screen and (min-width: 800px) {
		display: none;
	}
`
