import React from "react"
import styled from "styled-components"
import { SITE_NAME, SITE_URL } from "../../../config/index.js"
import { darkAlt, gold, Container } from "../../styles/index.js"
import { formatYear } from "../../utils/index.js"

export default function Socket() {
	return (
		<SocketOuter as="footer" modifiers={["full_width"]}>
			<SiteLink href="https://jamesgleason.tech">
				<span>Designed & Developed by:</span>
				<span>James Gleason</span>
			</SiteLink>
			<SiteLink href={SITE_URL}>
				<span>© {formatYear(Date.now())} All rights reserved</span>
				<span className="none_at_small">|</span>
				<span>{SITE_NAME}</span>
			</SiteLink>
		</SocketOuter>
	)
}

const SocketOuter = styled(Container)`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 15px 14px;
	color: ${darkAlt};

	& a:link,
	& a:visited {
		color: ${darkAlt};
	}
	& a:hover {
		color: ${gold};
	}

	span {
		display: block;
		margin: 3px;
	}
	.none_at_small {
		display: none;
	}

	@media only screen and (min-width: 500px) {
		span,
		.none_at_small {
			display: inline;
		}
	}

	@media only screen and (min-width: 800px) {
		flex-direction: row;
		justify-content: space-between;

		a:first-of-type {
			margin-left: auto;
		}
		a:last-of-type {
			margin-right: auto;
		}
	}
`

const SiteLink = styled.a`
	font-size: 0.875rem;
	margin: 4px 0;
	transition: all 250ms ease;
`
