import styled from "styled-components"
import { applyStyleModifiers } from "styled-components-modifiers"
import { white, lightestGrey } from "./colors.js"
import { elevation } from "./elevation.js"

const CARD_MODIFIER = {
	left: () => `
    text-align: left;
  `,
	center: () => `
    text-align: center;
  `,
	horizontalWhiteGrey: () => `
    background: linear-gradient(180deg, ${white}, ${lightestGrey});
  `,
}

export const Card = styled.div`
	@media only screen and (max-width: 500px) {
		padding: 36px 12px;
	}

	position: relative;
	border-radius: 24px;
	padding: 36px;
	overflow: hidden;
	background: ${white};
	${elevation[4]};
	${applyStyleModifiers(CARD_MODIFIER)};
`
