import { Component } from "react"
import ReactDOM from "react-dom"
import { isBrowser } from "../utils/isBrowser.js"

const portalRoot = isBrowser ? document.getElementById("portal") : null

export class Portal extends Component {
	constructor() {
		super()
		this.el = isBrowser ? document.createElement("div") : null
	}

	componentDidMount() {
		this.el ? portalRoot.appendChild(this.el) : null
	}

	componentWillUnmount() {
		this.el ? portalRoot.removeChild(this.el) : null
	}

	render() {
		const { children } = this.props
		return this.el ? ReactDOM.createPortal(children, this.el) : null
	}
}
