function getPublicEnv() {
	const shared = {
		SITE_NAME: "James Gleason // Web Media // Web Design",
		SITE_URL: "https://jamesgleason.tech",
	}

	const development = {
		...shared,
		FRONTEND_URL: "localhost:8000",
		CONTACT_WORKER_URL: "http://127.0.0.1:8787",
	}

	const production = {
		...shared,
		FRONTEND_URL: "jamesgleason.tech",
		CONTACT_WORKER_URL: "https://jamesgleason-tech-contact-form.sc-prod.workers.dev",
	}

	// Check if file is being used in a node process
	if (typeof global !== "undefined") {
		return process.env.NODE_ENV === "development" ? development : production
	}

	if (window.location.host.includes(production.FRONTEND_URL)) {
		return production
	}

	const local = {
		NODE_ENV: "local",
		FRONTEND_URL: `http://${window.location.host}`,
		GQL_SERVER_ENDPOINT_BASE: `http://${window.location.host.split(":")[0]}:4001`,
		...shared,
	}

	const IS_IP_ADDRESS =
		/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/.test(
			window.location.host,
		)
	if (IS_IP_ADDRESS) {
		return local
	}

	return development
}

export default getPublicEnv()
