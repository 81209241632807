import React from "react"
import styled from "styled-components"
import { white, darkAlt, slate, elevation } from "../styles"

export function SkipToContentBtn() {
	return <SkipToContent href="#content">Skip To Content</SkipToContent>
}

const SkipToContent = styled.a`
	position: absolute;
	top: -1000px;
	left: -1000px;
	width: 1px;
	height: 1px;
	overflow: hidden;
	z-index: -99;

	&:active,
	&:focus,
	&:hover {
		background: ${white};
		text-align: center;
		color: ${darkAlt};
		font-size: 0.905rem;
		font-weight: 600;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		padding: 14px;
		border: solid 3px ${slate};
		border-radius: 100px;
		top: 80px;
		left: 50%;
		outline: none;
		transform: translateX(-50%);
		width: 12rem;
		height: auto;
		overflow: visible;
		z-index: 100;
		${elevation[4]};
	}
`
