import styled from "styled-components"
import { Link } from "gatsby"

import { hoverColor, darkAlt } from "./colors.js"

export const Anchor = styled(Link)`
	color: ${({ color }) => color || `${darkAlt}`};
	transition: color 0.15s ease;

	&:link,
	&:visited {
		color: ${({ color }) => color || `${darkAlt}`};
	}

	&:hover {
		color: ${({ hover }) => hover || `${hoverColor}`};
	}
`
