import { useState, useEffect } from "react"
import { debounce } from "./debounce"

export function useIsPathnameMatch(pathname, pathnameToMatch) {
	const [currentPathname, setCurrentPathname] = useState(false)

	useEffect(() => {
		if (pathnameToMatch.includes(pathname)) {
			return setCurrentPathname(true)
		}
		return () => setCurrentPathname(false)
	}, [pathname])

	return currentPathname
}

export function useScrollY() {
	const [scrollY, setScrollY] = useState(typeof window !== "undefined" && window.scrollY)

	useEffect(() => {
		// eslint-disable-next-line func-style
		const handleScroll = () => setScrollY(typeof window !== "undefined" && window.scrollY)

		if (typeof window !== "undefined") {
			window.addEventListener("scroll", debounce(handleScroll))
		}

		return () => typeof window !== "undefined" && window.removeEventListener("scroll", debounce(handleScroll))
	}, [debounce])

	return scrollY
}
