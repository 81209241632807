import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTransition, animated } from "react-spring"
import { Portal } from "../../lib/Portal.js"
import { ContactBtn } from "../../lib/ContactBtn.js"
// eslint-disable-next-line
import { LogoLink } from "../../assets/logos"
// eslint-disable-next-line
import { Anchor, white, darkAlt, lightGreen } from "../../styles"
// eslint-disable-next-line
import { mainMenuLinks } from "./mainMenuLinks"
// eslint-disable-next-line
import MenuCloseButton from "./MobileMenuClose"

MobileMenu.propTypes = {
	toggle: PropTypes.func.isRequired,
	on: PropTypes.bool.isRequired,
}

export default function MobileMenu({ on, toggle }) {
	const transition = useTransition(on, null, {
		tension: 220,
		friction: 20,
		from: {
			opacity: 0.5,
			left: "-110vw",
			width: "0%",
		},
		enter: {
			opacity: 1,
			left: "0",
			width: "100%",
		},
		leave: {
			opacity: 1,
			left: "-110vw",
			width: "0%",
		},
	})

	return (
		<Portal>
			{transition.map(
				({ item, key, props: animation }) =>
					item && (
						<MenuBlock
							key={key}
							style={{
								opacity: animation.opacity.interpolate(op => `${op}`),

								transform: animation.left.interpolate(l => `translate3d(${l}, 0, 0)`),
								width: animation.width.interpolate(w => `${w}`),
							}}
						>
							<StyledLogoLink name="logo" fill={white} hover={lightGreen} />
							<LinksList>
								{mainMenuLinks.map(({ key: id, name, path }) => (
									<li key={id}>
										<Anchor to={path} color={white}>
											{name}
										</Anchor>
									</li>
								))}
								{/* <li>
									<Anchor href="/blog" target="self" color={white}>
										Blog
									</Anchor>
								</li> */}
							</LinksList>
							<BtnBox>
								<ContactBtn light>Contact</ContactBtn>
							</BtnBox>
							<MenuCloseButton on={on} toggle={toggle} />
						</MenuBlock>
					),
			)}
		</Portal>
	)
}

const MenuBlock = styled(animated.div)`
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 2rem;
	min-height: 100vh;
	background: ${darkAlt};
	z-index: 100;
`

const StyledLogoLink = styled(LogoLink)`
	position: absolute;
	top: 1.375rem;
	left: 1rem;
	height: auto;
`

const LinksList = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 3.5rem 3rem;
	text-transform: uppercase;
	flex: 3;

	li {
		padding: 0.375rem;
		list-style: none;
	}

	a {
		color: ${white};
		font-size: 1.375rem;
	}

	a:hover {
		color: ${lightGreen};
		transition: all 250ms ease;
	}
`

const BtnBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	flex: 1;
`
