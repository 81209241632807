import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { brandFont } from "../styles"
import { Icon } from "./Icon"

/* eslint-disable react/require-default-props */
IconHeading.propTypes = {
	name: PropTypes.string,
	color: PropTypes.string,
	hover: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	marginTop: PropTypes.number,
	reverse: PropTypes.bool,
	children: PropTypes.node,
}

export function IconHeading({ name, color, hover, width, height, marginTop, reverse, children }) {
	return (
		<IconHeadingBlock color={color} hover={hover} reverse={reverse} name={name} marginTop={marginTop}>
			{name && <Icon name={name} fill={color} width={width} height={height} />}
			<sup>{children}</sup>
		</IconHeadingBlock>
	)
}

const IconHeadingBlock = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	${brandFont};
	color: ${({ color }) => color};

	${({ name }) => (name ? "margin-bottom: 0.25rem;" : "margin-bottom: 0.5rem;")};

	${({ name }) => name && "@media screen and (max-width: 600px) { margin-left: -0.75rem; }"};

	${({ reverse }) => reverse && "flex-direction: row-reverse;"};

	@media only screen and (min-width: 500px) {
		${({ hover }) =>
			hover &&
			`
      &:hover path {
        fill: ${hover};
      }
      &:hover sup {
        color: ${hover};
      }
    `};
	}

	sup {
		color: ${({ color }) => color};
		font-weight: 700;
		font-size: 0.875rem;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		transition: all 250ms ease;

		${({ name }) => name && "margin-bottom: -0.3rem;"};
		${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`};
		${({ reverse }) => (reverse ? "margin-right: 0.25rem;" : "margin-left: 0.25rem;")};
	}

	svg {
		${({ reverse }) => (reverse ? "margin-bottom: 0.25rem;" : "margin-bottom: 0.5rem;")};
	}
	path {
		transition: all 250ms ease;
	}
`
