import styled, { keyframes } from "styled-components"
import { theme } from "./colors"
import { elevation } from "./elevation"

const DropDown = styled.div`
	position: absolute;
	width: 100%;
	z-index: 2;
	border: 1px solid ${theme.fadedSlate};

	p {
		font-size: 14px;
	}
`

const DropDownItem = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${theme.fadedSlate};
	background: ${props => (props.highlighted ? "#f7f7f7" : "white")};
	transition: all 0.2s;
	border-left: 10px solid ${props => (props.highlighted ? theme.darkAlt : "white")};
	cursor: pointer;
	${elevation[2]};

	${props => (props.highlighted ? "padding-left: 20px;" : null)};
	min-height: 58px;

	img {
		margin-right: 10px;
		max-width: 60px;
		max-height: 60px;
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	span {
		font-weight: bolder;
	}

	p {
		margin: 0;
	}
`

const glow = keyframes`
  from {
    box-shadow: 0 0 0px yellow;
  }
  to {
    box-shadow: 0 0 10px 1px yellow;
  }
`

const SearchStyles = styled.div`
	position: relative;
	input {
		width: 100%;
		padding: 10px;
		border: solid 1px ${theme.darkAlt};
		font-size: 20px;
		font-weight: 400;
		width: 100%;
		margin: 0;
		border-radius: 4px;

		&.loading {
			animation: ${glow} 0.5s ease-in-out infinite alternate;
		}
	}
`

export { DropDown, DropDownItem, SearchStyles }
