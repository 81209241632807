import React from "react"
import styled from "styled-components"
import { theme, elevation } from "../styles"
import { Icon } from "./Icon"

export function ScrollToTopBtn() {
	function scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
	}

	return (
		<STTBtnBg type="button" aria-label="scroll to top" onClick={() => scrollToTop()}>
			<Icon name="chevUp" fill={theme.darkSlate} height={22} width={22} />
		</STTBtnBg>
	)
}

const STTBtnBg = styled.button`
	cursor: pointer;
	position: fixed;
	bottom: 2%;
	right: 1%;
	background: ${theme.ghost};
	height: 40px;
	width: 40px;
	padding-top: 2px;
	outline: none;
	border: none;
	border-radius: 50%;
	z-index: 100;
	pointer-events: auto;
	transition: all 250ms ease;
	${elevation[3]};
	transform-origin: center;

	&:hover {
		transform: scale(1.05);
		${elevation[4]};
	}
`
